* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.app {
  width: 100%;
}
